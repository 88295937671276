import $ from "jquery";
import { Controller } from "stimulus";

export default class extends Controller {
  public connect() {
    if (this.data.has("action")) {
      $(this.element).dropdown({
        action: this.data.get("action")
      });
    } else {
      $(this.element).dropdown({
        onChange: (value) => {
          dispatchEvent(new CustomEvent("dropdown:change", {
            detail: { value }
          }));
        }
      });
    }
  }

  public disconnect() {
    $(this.element).dropdown("destroy");
  }
}
