import $ from "jquery";
import { Controller } from "stimulus";

export default class extends Controller {
  public connect() {
    // Activate the first tab when not specified
    if ($(".ui.menu .active.item").length === 0) {
      $(".ui.menu .item").first().
        addClass("active");
    }

    if ($(".ui.active.tab").length === 0) {
      $(".ui.tab").first().
        addClass("active");
    }

    $(".ui.menu .item").tab();
  }
}
