import { formatDistance, subWeeks } from "date-fns";
import { Controller } from "stimulus";

enum Format {
  ConciseWithRelative = 1,
  ConciseWithoutRelative = 2,
  // 2:03 PM on Jul 15, 2017
  Absolute = 3,
  // July 15, 2017
  DateOnly = 4
}

export default class TimeFormatController extends Controller {
  private datetimeCache!: Date;
  private currentDate!: Date;
  private formatCache!: number;

  public connect() {
    this.currentDate = new Date();
    this.renderConciseFormat();
  }

  // Format 1 has the following rules:
  //   1. Within 2 weeks: display relative time (eg. 2 days ago).
  //   2. Same year as current year: display only day, month, and time.
  //   3. Display day, month, year, and time.
  // eslint-disable-next-line max-statements
  private renderConciseFormat() {
    // tslint:disable-next-line:no-magic-numbers
    if (this.format === Format.ConciseWithRelative && subWeeks(this.currentDate, 2) < this.datetime) {
      this.renderRelative();
      return;
    }

    const parts: string[] = [];
    if (this.format === Format.DateOnly) {
      this.element.innerHTML =
        this.toLocaleDateString(
          { year: "numeric",
            month: "long",
            day: "numeric" }
        );
    } else if (this.format === Format.Absolute) {
      this.element.innerHTML = [
        this.toLocaleTimeString(
          { hour: "numeric",
            minute: "numeric" }
        ),
        this.toLocaleDateString(
          { year: "numeric",
            month: "short",
            day: "numeric" }
        )
      ].join(" on ");
    } else {
      if (this.datetime.getFullYear() === this.currentDate.getFullYear()) {
        parts.push(this.toLocaleDateString({
          month: "short",
          day: "numeric"
        }));
      } else {
        parts.push(this.toLocaleDateString({
          year: "numeric",
          month: "short",
          day: "numeric"
        }));
      }

      parts.push(this.toLocaleTimeString({
        hour: "numeric",
        minute: "numeric"
      }));

      this.element.innerHTML = parts.join(" - ");
    }
  }

  private renderRelative() {
    this.element.innerHTML = formatDistance(this.datetime, this.currentDate, {
      addSuffix: true
    });
  }

  private toLocaleDateString(options: any): string {
    return this.datetime.toLocaleDateString(this.locale, options);
  }

  private toLocaleTimeString(options: any): string {
    return this.datetime.toLocaleTimeString(this.locale, options);
  }

  private get locale(): string {
    return navigator.language;
  }

  private get format(): number {
    if (!this.formatCache) {
      if (this.data.has("format")) {
        this.formatCache = parseInt(this.data.get("format")!, 10);
        if (this.formatCache < Format.ConciseWithRelative || this.formatCache > Format.DateOnly) {
          this.formatCache = Format.ConciseWithRelative;
        }
      } else {
        this.formatCache = Format.ConciseWithRelative;
      }
    }
    return this.formatCache;
  }

  private get datetime(): Date {
    if (!this.datetimeCache) {
      const element = this.element as HTMLElement;
      this.datetimeCache = new Date(element.getAttribute("datetime") as string);
    }
    return this.datetimeCache;
  }
}
