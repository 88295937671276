import { Controller } from "stimulus";
import InfiniteScroll from "infinite-scroll";

export default class InfiniteScrollController extends Controller {
  public static targets = [
    "content",
    "spinner"
  ];

  private contentTarget!: HTMLElement;
  private spinnerTarget!: HTMLElement;
  private infiniteScroll: InfiniteScroll;

  public connect() {
    if (!this.fetchPath) {
      return;
    }

    this.infiniteScroll = new InfiniteScroll(this.contentTarget, {
      path: () => this.fetchPath,
      responseType: "json",
      history: false,
      elementScroll: this.elementScroll,
      onInit: (infiniteScroll: any) => {
        infiniteScroll.on("request", this.infiniteScrollRequestHandler);
        infiniteScroll.on("load", this.infiniteScrollLoadHandler);
        infiniteScroll.on("error", this.infiniteScrollErrorHandler);
      }
    });

    this.infiniteScroll.prefill();
    addEventListener("load", this.windowLoadHandler);
  }

  public windowLoadHandler = () => {
    this.infiniteScroll.updateMeasurements();
  };

  public disconnect() {
    removeEventListener("load", this.windowLoadHandler);
    if (this.infiniteScroll) {
      this.infiniteScroll.destroy();
    }
  }

  private infiniteScrollRequestHandler = () => {
    this.startProcessing();
  };

  private infiniteScrollLoadHandler = (response: any) => {
    this.stopProcessing();

    this.fetchPath = response[this.pathName];

    const doc = (new DOMParser()).parseFromString(response.html, "text/html");
    this.infiniteScroll.appendItems(doc.querySelectorAll("body > *"));

    if (this.infiniteScroll.isPrefilling) {
      this.infiniteScroll.prefill();
    }

    document.dispatchEvent(new CustomEvent("infiniteScroll:load"));

    // Trigger a new pageview and event for google analytics
    if (window.gtag) {
      window.gtag("config", this.googleId, {});
      window.gtag("event", "Scroll Pageview");
    }
  };

  private infiniteScrollErrorHandler = () => {
    this.stopProcessing();
  };

  private startProcessing() {
    this.spinnerTarget.classList.add("active");
  }

  private stopProcessing() {
    this.spinnerTarget.classList.remove("active");
  }

  private get fetchPath(): string {
    return this.data.get("path") as string;
  }

  private set fetchPath(value: string) {
    if (value) {
      this.data.set("path", value);
    } else {
      this.data.delete("path");
    }
  }

  private get pathName(): string {
    return this.data.get("pathName") as string;
  }

  // The elementScroll value null is default option, the scroller is window.
  private get elementScroll(): string | null {
    return this.data.get("elementScroll");
  }

  private get googleId(): string {
    return this.data.get("googleId")!;
  }
}
