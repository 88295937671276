import { Controller } from "stimulus";
import Rails from "rails-ujs";

export default class ImageUploadFormController extends Controller {
  public static targets = [
    "coverForm",
    "coverFileField",
    "coverSubmitItem",
    "coverSubmittingIcon",
    "coverDeleteLink",
    "avatarForm",
    "avatarFileField",
    "avatarSubmitItem",
    "avatarSubmittingIcon",
    "avatarDeleteLink",
    "errorMessage",
    "successMessage",
    "submitting"
  ];

  private coverFormTarget: HTMLFormElement;
  private coverFileFieldTarget: HTMLInputElement;
  private coverSubmitItemTarget: HTMLInputElement;
  private coverSubmittingIconTarget: HTMLElement;
  private coverDeleteLinkTarget: HTMLLinkElement;
  private hasCoverSubmittingIconTarget: boolean;
  private hasCoverDeleteLinkTarget: boolean;
  private avatarFormTarget: HTMLFormElement;
  private avatarFileFieldTarget: HTMLInputElement;
  private avatarSubmitItemTarget: HTMLInputElement;
  private avatarSubmittingIconTarget: HTMLElement;
  private avatarDeleteLinkTarget: HTMLLinkElement;
  private hasAvatarSubmittingIconTarget: boolean;
  private hasAvatarDeleteLinkTarget: boolean;
  private errorMessageTarget: HTMLElement;
  private successMessageTargets: HTMLElement[];
  private hasErrorMessageTarget: boolean;
  private hasSuccessMessageTarget: boolean;
  private submitting = false;

  // eslint-disable-next-line max-statements
  public connect() {
    this.element.addEventListener("direct-uploads:start", this.directUploadsStartHandler);
    this.element.addEventListener("direct-uploads:end", this.directUploadsEndHandler);
    this.element.addEventListener("ajax:before", this.ajaxBeforeHandler);
    this.element.addEventListener("ajax:complete", this.ajaxCompleteHandler);
    this.element.addEventListener("ajax:error", this.ajaxErrorHandler);
    this.coverFileFieldTarget.addEventListener("change", this.fileFieldChangeHandler);
    this.avatarFileFieldTarget.addEventListener("change", this.fileFieldChangeHandler);
    this.coverSubmitItemTarget.addEventListener("click", this.submitItemClickHandler);
    this.avatarSubmitItemTarget.addEventListener("click", this.submitItemClickHandler);
    if (this.hasCoverDeleteLinkTarget) {
      this.coverDeleteLinkTarget.addEventListener("confirm:complete", this.confirmCompleteHandler);
    }
    if (this.hasAvatarDeleteLinkTarget) {
      this.avatarDeleteLinkTarget.addEventListener("confirm:complete", this.confirmCompleteHandler);
    }
  }

  // eslint-disable-next-line max-statements
  public disconnect() {
    this.element.removeEventListener("direct-uploads:start", this.directUploadsStartHandler);
    this.element.removeEventListener("direct-uploads:end", this.directUploadsEndHandler);
    this.element.removeEventListener("ajax:before", this.ajaxBeforeHandler);
    this.element.removeEventListener("ajax:complete", this.ajaxCompleteHandler);
    this.element.removeEventListener("ajax:error", this.ajaxErrorHandler);
    this.coverFileFieldTarget.removeEventListener("change", this.fileFieldChangeHandler);
    this.avatarFileFieldTarget.removeEventListener("change", this.fileFieldChangeHandler);
    this.coverSubmitItemTarget.removeEventListener("click", this.submitItemClickHandler);
    this.avatarSubmitItemTarget.removeEventListener("click", this.submitItemClickHandler);
    if (this.hasCoverDeleteLinkTarget) {
      this.coverDeleteLinkTarget.removeEventListener("confirm:complete", this.confirmCompleteHandler);
    }
    if (this.hasAvatarDeleteLinkTarget) {
      this.avatarDeleteLinkTarget.removeEventListener("confirm:complete", this.confirmCompleteHandler);
    }
  }

  private fileFieldChangeHandler = (event) => {
    this.submit(event.target);
  };

  private submitItemClickHandler = (event) => {
    if (this.submitting) {
      return false;
    }
    if (event.target === this.coverSubmittingIconTarget || event.target === this.coverSubmitItemTarget) {
      this.coverFileFieldTarget.click();
    } else {
      this.avatarFileFieldTarget.click();
    }
  };

  private directUploadsStartHandler = (event) => {
    this.ajaxBeforeHandler(event);
  };

  // eslint-disable-next-line max-statements
  private ajaxBeforeHandler = (event) => {
    if (this.submitting) {
      event.preventDefault();
      return false;
    }
    this.submitting = true;
    if (this.hasCoverDeleteLinkTarget) {
      this.coverDeleteLinkTarget.classList.add("hidden");
    }
    if (this.hasAvatarDeleteLinkTarget) {
      this.avatarDeleteLinkTarget.classList.add("hidden");
    }
    if (event.target === this.coverFormTarget && this.hasCoverSubmittingIconTarget) {
      this.coverSubmittingIconTarget.classList.remove("camera");
      this.coverSubmittingIconTarget.classList.add("loader");
    }
    if (event.target === this.avatarFormTarget && this.hasAvatarSubmittingIconTarget) {
      this.avatarSubmittingIconTarget.classList.remove("camera");
      this.avatarSubmittingIconTarget.classList.add("loader");
    }
  };

  // eslint-disable-next-line max-statements
  private ajaxCompleteHandler = (event) => {
    if (this.submitting) {
      this.submitting = false;
      if (this.hasCoverDeleteLinkTarget) {
        this.coverDeleteLinkTarget.classList.remove("hidden");
      }
      if (this.hasAvatarDeleteLinkTarget) {
        this.avatarDeleteLinkTarget.classList.remove("hidden");
      }
      if (event.target === this.coverFormTarget && this.hasCoverSubmittingIconTarget) {
        this.coverSubmittingIconTarget.classList.remove("loader");
        this.coverSubmittingIconTarget.classList.add("camera");
      }
      if (event.target === this.avatarFormTarget && this.hasAvatarSubmittingIconTarget) {
        this.avatarSubmittingIconTarget.classList.remove("loader");
        this.avatarSubmittingIconTarget.classList.add("camera");
      }
    }
  };

  private directUploadsEndHandler = (event) => {
    this.ajaxCompleteHandler(event);
  };

  private submit(src) {
    if (src === this.coverFileFieldTarget) {
      Rails.fire(this.coverFormTarget, "submit");
    } else {
      Rails.fire(this.avatarFormTarget, "submit");
    }
  }

  private confirmCompleteHandler = (event) => {
    if (!event.detail[0]) {
      event.preventDefault();
      return false;
    }
  };

  private ajaxErrorHandler = () => {
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.classList.add("active");
    }
    if (this.hasSuccessMessageTarget) {
      this.successMessageTargets.forEach((msg) => {
        msg.classList.remove("active");
      });
    }
  };
}
