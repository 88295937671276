import { Controller } from "stimulus";

export default class AtomicRequestsController extends Controller {
  public connect() {
    this.element.
      addEventListener("ajax:beforeSend", this.ajaxBeforeSendHandler);
    this.element.
      addEventListener("ajax:complete", this.ajaxCompleteHandler);
    this.element.
      addEventListener("click", this.clickHandler);
  }

  public disconnect() {
    this.element.
      removeEventListener("ajax:beforeSend", this.ajaxBeforeSendHandler);
    this.element.
      removeEventListener("ajax:complete", this.ajaxCompleteHandler);
    this.element.
      removeEventListener("click", this.clickHandler);
  }

  private ajaxBeforeSendHandler = (event) => {
    this.canMakeAtomicRequest(event);
    this.element.dispatchEvent(
      new CustomEvent("atomic-requests:lock", {
        bubbles: true
      })
    );
  };

  private ajaxCompleteHandler = () => {
    this.data.delete("atomic-lock");
    this.element.dispatchEvent(
      new CustomEvent("atomic-requests:unlock", {
        bubbles: true
      })
    );
  };

  private clickHandler = (event) => {
    if (this.element === event.target && !this.canMakeAtomicRequest(event)) {
      event.preventDefault();
    }
  };

  private canMakeAtomicRequest(event): boolean {
    if (this.isLock) {
      event.preventDefault();
      return false;
    }

    this.data.set("atomic-lock", "true");
    return true;
  }

  private get isLock(): boolean {
    return this.data.has("atomic-lock");
  }
}
