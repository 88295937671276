import { Definition } from "@stimulus/core";
import { identifierForContextKey } from "stimulus/webpack-helpers";

// Load all Stimulus controllers.
const controllersContext = require.context(
  "semantic-ui/controllers",
  true,
  /[_-]controller\.ts$/u
);

const definitionForModuleAndIdentifier = (module: any, identifier: string) => {
  const controllerConstructor = module.default;

  if (typeof controllerConstructor === "function") {
    return {
      identifier,
      controllerConstructor
    };
  }
};

const definitionForModuleWithContextAndKey = (context: any, key: string) => {
  const identifier = identifierForContextKey(key);

  if (identifier) {
    return definitionForModuleAndIdentifier(
      context(key),
      `semantic-ui-${identifier}`
    );
  }
};

export const definitionsFromSemanticUi = controllersContext.keys().
  map(
    (key: string) => definitionForModuleWithContextAndKey(
      controllersContext,
      key
    )
  ).
  filter(
    (value: any) => value
  ) as Definition[];
