import $ from "jquery";
import { Controller } from "stimulus";

export default class extends Controller {
  public connect() {
    // TODO: figure out how to reposition and display popup at the center
    $(this.element).popup({
      position: "top center",
      hoverable: true
    });
  }
}
