import { Controller } from "stimulus";

export default class FormValidationController extends Controller {
  public static targets = [
    "validation"
  ];

  private validationTargets!: HTMLInputElement[];
  private mutationObserver!: MutationObserver;

  public connect() {
    this.mutationObserver = new MutationObserver(this.mutationObserverHandler);

    this.validationTargets.forEach((target) => {
      this.mutationObserver.observe(target, {
        attributes: true,
        attributeFilter: [
          "data-valid",
          "disabled"
        ]
      });
    });

    this.mutationObserverHandler();
  }

  public disconnect() {
    this.mutationObserver.disconnect();
  }

  private mutationObserverHandler = () => {
    let eventName = "form-validation:failed";

    if (this.validationTargets.every(this.validateTarget)) {
      eventName = "form-validation:success";
    }

    this.element.dispatchEvent(
      new CustomEvent(eventName, {
        bubbles: true
      })
    );
  };

  private validateTarget(target: HTMLInputElement): boolean {
    return target.disabled || target.dataset.valid === "true";
  }
}
