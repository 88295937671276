import { Controller } from "stimulus";
// tslint:disable-next-line:no-submodule-imports
import Honeybadger from "honeybadger-js/honeybadger";

export default class HoneybadgerController extends Controller {
  public initialize() {
    Honeybadger.configure({
      apiKey: "1bdae49a",
      environment: "production",
      revision: "master",
      debug: true
    });
  }
}
