import $ from "jquery";
import { Controller } from "stimulus";
import debounce from "lodash.debounce";

export default class EmailInputController extends Controller {
  public static targets = [
    "input"
  ];

  // tslint:disable-next-line:max-line-length
  private static emailPatten: RegExp = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w{2,}([-.]\w+)*$/u;
  private static emailShortestLength: number = 3;
  private static emailLongestLength: number = 100;
  private static validateDelay: number = 500;
  private inputTarget: HTMLInputElement;
  private popup: $;
  private debouncedUpdateErrorPopup!: () => void;

  public connect() {
    this.inputTarget.addEventListener("input", this.targetInputHandler);
    this.debouncedUpdateErrorPopup = debounce(this.updateErrorPopup, EmailInputController.validateDelay);
    this.popup = $(this.inputTarget).popup({
      content: "Invalid email",
      className: {
        popup: "ui mini inverted popup"
      },
      position: "bottom left",
      on: "manual"
    });
  }

  public disconnect() {
    this.inputTarget.removeEventListener("input", this.targetInputHandler);
  }

  private targetInputHandler = () => {
    const validEmail = this.isValidEmail();
    this.inputTarget.dataset.valid = validEmail.toString();

    if (validEmail) {
      this.updateErrorPopup();
    } else {
      this.debouncedUpdateErrorPopup();
    }
  };

  private updateErrorPopup = () => {
    let display = "show";

    if (this.email === "" || this.isValidEmail()) {
      display = "hide";
    }

    this.popup.popup(display);
  };

  private get email(): string {
    return this.inputTarget.value;
  }

  private isValidEmail(): boolean {
    const emailLength = this.email.length;

    return EmailInputController.emailPatten.test(this.email) &&
      emailLength >= EmailInputController.emailShortestLength &&
      emailLength <= EmailInputController.emailLongestLength;
  }
}
