import $ from "jquery";
import { Controller } from "stimulus";

export default class AccordionController extends Controller {
  public connect(): void {
    $(this.element).accordion({
      duration: this.duration
    });
  }

  public disconnect() {
    $(this.element).accordion("destroy");
  }

  private get duration(): number {
    return parseInt(this.data.get("duration") || "500", 10);
  }
}
