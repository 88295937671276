import { Controller } from "stimulus";

export default class InstagramReferenceFormController extends Controller {
  public static targets = [
    "form",
    "submitButton"
  ];

  private formTarget!: HTMLFormElement;
  private submitButtonTarget!: HTMLInputElement;

  public connect() {
    this.formTarget.addEventListener("atomic-requests:lock", this.submitHandler);
  }

  public disconnect() {
    this.formTarget.removeEventListener("atomic-requests:lock", this.submitHandler);
  }

  private submitHandler = () => {
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.classList.add("loading");
  };
}
