import $ from "jquery";
import ClipboardJS from "clipboard";
import { Controller } from "stimulus";

export default class ClipboardController extends Controller {
  public static targets = [
    "content"
  ];

  private static hideTimeout: number = 1500;

  private clipboard: ClipboardJS;
  private hasContentTarget: boolean;
  private contentTarget: HTMLInputElement;
  private hideTimeoutId!: number;

  public connect(): void {
    // Clipboard, by default, looks for the "data-clipboard-text" attribute on
    // the element.
    this.clipboard = new ClipboardJS(this.element);
    this.clipboard.on("success", this.clipboardSuccessHandler);
    // Handle errors with:
    // this.clipboard.on("error", this.clipboardErrorHandler);
  }

  public disconnect(): void {
    this.clipboard.destroy();
    window.clearTimeout(this.hideTimeoutId);
  }

  // eslint-disable-next-line max-statements
  private clipboardSuccessHandler = (): void => {
    if (this.hasContentTarget && this.contentTarget.disabled) {
      return;
    }
    const options = {
      on: "manual",
      position: this.data.get("popup-position") || "bottom center",
      transition: "drop",
      html: "<i class=\"clipboard check icon\"></i> Copied!",
      className: {
        popup: "ui inverted popup"
      }
    } as any;

    if (this.data.has("popup-distance-away")) {
      options.distanceAway = parseInt(
        this.data.get("popup-distance-away")!,
        10
      );
    }

    // Pass to semantic-ui's popup: Can be a selector or jQuery object.
    // Specified this allows the popup to be positioned relative to that element.
    if (this.data.has("popup-target")) {
      options.target = this.data.get("popup-target");
    }

    if (this.data.has("popup-inline")) {
      options.inline = this.data.get("popup-inline") === "true";
      // Use pre-existing popup to prevent generating duplicated inline popup element
      // Duplicated popup will be unable to dismiss
      delete options.html;
    }

    if (this.hideTimeoutId) {
      window.clearTimeout(this.hideTimeoutId);
    }

    this.hideTimeoutId = window.setTimeout(
      this.popupTimeoutHideHandler,
      ClipboardController.hideTimeout,
      $(this.element).popup(options).
        popup("show")
    );
  };

  private popupTimeoutHideHandler = (popup: $) => {
    if (popup.popup("is visible")) {
      popup.popup("hide");
    }
  };
}
