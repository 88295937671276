import { Controller } from "stimulus";

export default class PreviewImageController extends Controller {
  public static targets = [
    "fileInput",
    "previewImg"
  ];

  private previewImgTarget: HTMLElement;
  private fileInputTarget: HTMLInputElement;
  private reader!: FileReader;

  public connect() {
    this.reader = new FileReader();
    this.reader.addEventListener("load", this.fileReaderLoadHandler);
  }

  public disconnect() {
    if (this.reader) {
      this.reader.removeEventListener("load", this.fileReaderLoadHandler);
    }
  }

  public attach() {
    if (this.fileInputTarget.files && this.fileInputTarget.files[0]) {
      this.reader.readAsDataURL(this.fileInputTarget.files[0]);
    }
  }

  private fileReaderLoadHandler = () => {
    this.previewImgTarget.setAttribute("src", this.reader.result!.toString());
    this.previewImgTarget.style.display = "inline-block";
  };
}
