import $ from "jquery";
import ClipboardJS from "clipboard";
import { Controller } from "stimulus";

export default class ProfileDropdownController extends Controller {
  public static targets = [
    "dropdown",
    "buttonItem",
    "modalItem"
  ];

  private static hideTimeout = 1500;
  private static modalEvent = "profile-dropdown:modalOpen";
  private clipboard: ClipboardJS;
  private dropdownTarget!: HTMLElement;
  private buttonItemTarget!: HTMLElement;
  private modalItemTargets!: HTMLElement[];
  private hideTimeoutId: number;

  public connect() {
    $(this.dropdownTarget).dropdown({
      selectOnKeydown: false,
      onChange: this.dropdownOnChangeHandler,
      action: "select"
    });

    this.modalItemTargets.forEach((modalTarget) => {
      modalTarget.dataset.action =
        `${ProfileDropdownController.modalEvent}->semantic-ui-modal#show`;
    });

    this.clipboard = new ClipboardJS(this.buttonItemTarget);
    this.clipboard.on("success", this.clipboardSuccessHandler);
  }

  public disconnect() {
    this.clipboard.destroy();
    $(this.dropdownTarget).dropdown("destroy");
    window.clearTimeout(this.hideTimeoutId);
  }

  private clipboardSuccessHandler = () => {
    const options = {
      on: "click",
      position: "top center",
      transition: "drop",
      popup: ".ui.inverted.popup",
      target: ".ui.selection.dropdown"
    } as any;

    this.hideTimeoutId = window.setTimeout(
      this.popupTimeoutHideHandler,
      ProfileDropdownController.hideTimeout,
      $(this.buttonItemTarget).popup(options).
        popup("show")
    );
  };

  private popupTimeoutHideHandler = (popup: $) => {
    if (popup.popup("is visible")) {
      popup.popup("hide");
    }
  };

  private dropdownOnChangeHandler = (
    _value: any,
    _text: any,
    selectedElement: HTMLElement[]
  ) => {
    $(this.dropdownTarget).dropdown("restore default text");

    if (selectedElement[0].dataset.target === `${this.identifier}.modalItem`) {
      selectedElement[0].dispatchEvent(
        new CustomEvent(`${ProfileDropdownController.modalEvent}`, {
          bubbles: false,
          cancelable: true
        })
      );
    } else {
      selectedElement[0].click();
    }
  };
}
