import { Controller } from "stimulus";
export default class FlashController extends Controller {
  private message = "";

  public initialize(): void {
    if (this.data.has("message")) {
      this.message = this.data.get("message") as string;
      this.data.set("message", "");
    }
  }

  public connect(): void {
    if (this.message !== "") {
      // eslint-disable-next-line no-alert
      alert(this.message);
    }
  }
}
