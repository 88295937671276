import { Controller } from "stimulus";

export default class CharCountController extends Controller {
  public static targets = [
    "counter",
    "textArea"
  ];

  private counterTarget!: HTMLElement;
  private textAreaTarget!: HTMLTextAreaElement;

  public connect() {
    this.textAreaTarget.setAttribute(
      "maxlength",
      this.data.get("maxlength") as string
    );
    this.textAreaTarget.addEventListener("input", this.textAreaInputHandler);

    // Do count for text already in the text area.
    this.textAreaInputHandler();
  }

  public disconnect() {
    this.textAreaTarget.removeEventListener("input", this.textAreaInputHandler);
  }

  private textAreaInputHandler = () => {
    const charCount = this.textAreaTarget.value.length;
    const maxLength = this.data.get("maxlength");

    this.counterTarget.textContent = `${charCount}/${maxLength}`;
  };
}
