import { Controller } from "stimulus";
import shave from "shave";

export default class LineClampController extends Controller {
  public static readonly targets = [
    "clampable"
  ];

  private readonly clampableTargets!: HTMLElement[];
  private processingResize: boolean = false;

  public connect() {
    this.shaveAll();

    addEventListener("resize", this.documentResizeHandler);

    if (this.element === document.body) {
      if (document.readyState !== "complete") {
        addEventListener("load", this.shaveAll);
      }

      document.addEventListener("turbolinks:load", this.shaveAll);
      document.addEventListener("modal:visible", this.shaveAll);
      document.addEventListener("modal:reload", this.shaveAll);
      document.addEventListener("infiniteScroll:load", this.shaveAll);
    }
  }

  public disconnect() {
    removeEventListener("resize", this.documentResizeHandler);
    removeEventListener("load", this.shaveAll);
    document.removeEventListener("turbolinks:load", this.shaveAll);
    document.removeEventListener("modal:visible", this.shaveAll);
    document.removeEventListener("modal:reload", this.shaveAll);
    document.removeEventListener("infiniteScroll:load", this.shaveAll);
  }

  private documentResizeHandler = () => {
    if (!this.processingResize) {
      this.processingResize = true;

      requestAnimationFrame(() => {
        this.shaveAll();
        this.processingResize = false;
      });
    }
  };

  private shaveAll = () => {
    this.clampableTargets.forEach(this.shaveElement);
  };

  private shaveElement(element: HTMLElement) {
    if (element.dataset.lineClampMaxHeight) {
      shave(element, parseInt(element.dataset.lineClampMaxHeight, 10), { spaces: false });

      element.classList.remove("empty-content");

      if (element.querySelector(".js-shave")) {
        element.dispatchEvent(
          new CustomEvent("shave:didShave", {
            bubbles: true
          })
        );
      }
    }
  }
}
