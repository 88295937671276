import { Controller } from "stimulus";
import { UAParser } from "ua-parser-js";

export default class AmountInputTypeController extends Controller {
  public static targets = [
    "amountInput"
  ];

  private amountInputTarget!: HTMLInputElement;

  public initialize() {
    this.updateAmountInputType();
  }

  private updateAmountInputType() {
    // On mobile devices, set the input type to "number" to show numbers
    // on the keyboard. We don't want to do this for PCs because it will display
    // up/down arrows next to the input.
    const uaparser = new UAParser();
    if (uaparser.getDevice().type && uaparser.getOS().name !== "iOS") {
      // `number` will not be able to use `setSelectionRange` on mobile chrome, so
      // hack it to use "tel"
      const browser = uaparser.getBrowser().name || "";
      if (browser !== "Android Browser" && browser !== "Samsung Browser" &&
          browser !== "Chromium" && !browser.startsWith("Chrome")) {
        this.amountInputTarget.type = "tel";
      }
    }
  }
}
