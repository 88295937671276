import { Controller } from "stimulus";

export default class WindowsController extends Controller {
  private static readonly monitorInterval = 100;
  private originalWindowOpen: any;

  public initialize() {
    // Monkey patch windows.open so that we know everything popup that
    // opens and closes.
    this.originalWindowOpen = window.open;
    window.open = this.patchedWindowOpen;
  }

  private patchedWindowOpen = (...args): Window => {
    const detail = this.openArgsToEventDetail(args);
    dispatchEvent(new CustomEvent("windows:before", { detail }));

    const newWindow = this.originalWindowOpen.apply(window, args);
    dispatchEvent(new CustomEvent("windows:opened", {
      detail: {
        window: newWindow,
        ...detail
      }
    }));

    this.monitor(newWindow);
    return newWindow;
  };

  private monitor(childWindow: Window) {
    const interval = window.setInterval(() => {
      if (childWindow.closed) {
        window.clearInterval(interval);

        dispatchEvent(new CustomEvent("windows:closed", {
          detail: {
            window: childWindow
          }
        }));
      }
    }, WindowsController.monitorInterval);
  }

  private openArgsToEventDetail(openArgs) {
    const detail: any = {};
    [
      detail.url,
      detail.name,
      detail.specs,
      detail.replace
    ] = openArgs;

    return detail;
  }
}
