import { Controller } from "stimulus";

export default class extends Controller {
  public static targets = [
    "input"
  ];

  private inputTarget!: HTMLInputElement;

  public connect() {
    this.inputTarget.addEventListener("input", this.textInputHandler);

    this.textInputHandler();
  }

  public disconnect() {
    this.inputTarget.removeEventListener("input", this.textInputHandler);
  }

  private textInputHandler = () => {
    if (!this.allowBlank && this.inputTarget.value.match(/^\s+$/u)) {
      this.inputTarget.value = this.inputTarget.value.trim();
    }

    const valid = this.inputTarget.value !== "" &&
      this.inputTarget.value.length >= this.minLength &&
      !this.didChange;

    this.inputTarget.dataset.valid = valid.toString();
  };

  private get minLength(): number {
    return parseInt(this.data.get("minlength") || "0", 10);
  }

  private get allowBlank(): boolean {
    return this.data.get("allowBlank") === "true";
  }

  private get didChange(): boolean {
    return this.data.get("originalContent") === this.inputTarget.value;
  }
}
