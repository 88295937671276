import $ from "jquery";
import { Controller } from "stimulus";
import Rails from "rails-ujs";

export default class AvatarFormController extends Controller {
  public static targets = [
    "form",
    "dropdown",
    "fileField",
    "submitItem",
    "deleteLink",
    "errorMessage",
    "successMessage"
  ];

  private formTarget: HTMLFormElement;
  private dropdownTarget: HTMLElement;
  private hasDropdownTarget: boolean;
  private fileFieldTarget: HTMLInputElement;
  private submitItemTarget: HTMLInputElement;
  private deleteLinkTarget: HTMLLinkElement;
  private errorMessageTarget: HTMLElement;
  private successMessageTarget: HTMLElement;
  private hasSuccessMessageTarget: boolean;

  public connect() {
    if (this.hasDropdownTarget) {
      $(this.dropdownTarget).dropdown({
        action: "select",
        selectOnKeydown: false
      }).
        bind("keydown", this.dropdownItemKeyDown);
    }

    this.element.addEventListener("atomic-requests:lock", this.atomicRequestLockHandler);
    this.element.
      addEventListener("direct-uploads:start", this.directUploadsStartHandler);
    this.element.
      addEventListener("direct-upload:error", this.directUploadErrorHandler);
    this.element.
      addEventListener("ajax:error", this.ajaxErrorHandler);
    this.fileFieldTarget.
      addEventListener("change", this.fileFieldChangeHandler);
    this.submitItemTarget.
      addEventListener("click", this.submitItemClickHandler);
  }

  public disconnect() {
    this.element.
      removeEventListener(
        "direct-uploads:start",
        this.directUploadsStartHandler
      );
    this.element.
      removeEventListener("direct-upload:error", this.directUploadErrorHandler);
    this.element.removeEventListener("atomic-requests:lock", this.atomicRequestLockHandler);
    this.element.
      removeEventListener("ajax:error", this.ajaxErrorHandler);
    if (this.hasDropdownTarget) {
      $(this.dropdownTarget).dropdown("destroy");
    }
    this.fileFieldTarget.
      removeEventListener("change", this.fileFieldChangeHandler);
  }

  private fileFieldChangeHandler = () => {
    this.submit();
  };

  private submitItemClickHandler = () => {
    this.fileFieldTarget.click();
  };

  private directUploadsStartHandler = () => {
    this.submitItemTarget.classList.add("loading");
    this.submitItemTarget.disabled = true;
  };

  private atomicRequestLockHandler = () => {
    // Add loading spinner while deleting photo
    if (!this.submitItemTarget.classList.contains("loading")) {
      this.submitItemTarget.classList.add("loading");
      this.submitItemTarget.disabled = true;
    }
  };

  private ajaxErrorHandler = () => {
    this.formErroredHandler();
  };

  private directUploadErrorHandler = (event: Event) => {
    event.preventDefault();
    this.formErroredHandler();
  };

  private formErroredHandler() {
    this.submitItemTarget.classList.remove("loading");
    this.submitItemTarget.disabled = false;
    if (this.hasSuccessMessageTarget) {
      this.successMessageTarget.classList.remove("active");
    }
    this.errorMessageTarget.classList.add("active");
  }

  private submit() {
    Rails.fire(this.formTarget, "submit");
  }

  private dropdownItemKeyDown = (event) => {
    const enterKey = 13;
    if (event.keyCode === enterKey &&
      $(this.dropdownTarget).dropdown("get value") === "delete") {
      Rails.fire(this.deleteLinkTarget, "click");
    }
  };
}
