/* Cookies */
import Cookies from "js-cookie";
if (location.protocol === "https:") {
  Cookies.defaults = {
    secure: true
  };
}

/* Stimulus */
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import { definitionsFromSemanticUi } from "semantic-ui/controllers";
const application = Application.start();
application.load(definitionsFromContext(
  require.context("nft_bank/controllers", true, /\.ts$/u)
));
application.load(definitionsFromContext(
  require.context("common/controllers", true, /\.ts$/u)
));
application.load(definitionsFromSemanticUi);

/* Semantic UI */
import "semantic-ui";
import "semantic-ui/themes/nft_bank.less";

/* Application CSS */
import "stylesheets/nft_bank";

/* Import all assets */
require.context("assets/nft_bank", true);

/* jQuery for Commontator */
import "jquery";
import "rails-ujs";
