import "whatwg-fetch";
import { Controller } from "stimulus";

export default class PaymentPollingController extends Controller {
  private static readonly loaderDelay = 30000;
  private paymentPollingTimeoutId!: number;
  private pollable!: boolean;

  public connect() {
    this.pollable = this.data.get("pollable") === "true";

    this.paymentPollingTimeoutId =
      window.setTimeout(this.payment, this.loaderDelay);
    this.element.addEventListener("polling:stop", this.stopPollingHandler);
    this.element.addEventListener("polling:start", this.startPollingHandler);
  }

  public disconnect() {
    window.clearTimeout(this.paymentPollingTimeoutId);
    this.element.removeEventListener("polling:stop", this.stopPollingHandler);
    this.element.removeEventListener("polling:start", this.startPollingHandler);
  }

  private payment = () => {
    if (this.pollable) {
      fetch(this.paymentPath, {
        headers: { "X-Requested-With": "XMLHttpRequest" },
        credentials: "same-origin"
      }).
        then((response) => response.text()).
        then((html) => {
          const parentNode = this.element.parentNode;

          if (parentNode) {
            const doc = (new DOMParser()).parseFromString(html, "text/html");
            const newElement = doc.body.firstElementChild!;

            if (this.isEqual(newElement)) {
              window.setTimeout(this.payment, this.loaderDelay);
            } else {
              parentNode.replaceChild(newElement, this.element);
            }
          }
        });
    } else {
      window.setTimeout(this.payment, this.loaderDelay);
    }
  };

  private stopPollingHandler = () => {
    this.pollable = false;
  };

  private startPollingHandler = () => {
    this.pollable = true;
  };

  private get paymentPath(): string {
    return this.data.get("path")!;
  }

  private isEqual = (element: Element) => {
    const newElement = element as HTMLElement;

    if (this.data.get("state")) {
      return this.data.get("state") === newElement.dataset.paymentPollingState;
    }
    return this.element.isEqualNode(newElement);
  };

  private get loaderDelay(): number {
    if (this.data.get("delay") !== null) {
      return parseInt(this.data.get("delay")!, 10);
    }
    return PaymentPollingController.loaderDelay;
  }
}
