import $ from "jquery";
import { Controller } from "stimulus";

export default class extends Controller {
  public static targets = [
    "rating",
    "input"
  ];

  private ratingTarget: HTMLElement;
  private inputTarget: HTMLInputElement;

  public connect() {
    $(this.ratingTarget).rating({
      onRate: this.ratingRateHandler,
      fireOnInit: true
    });
  }

  public disconnect() {
    $(this.ratingTarget).rating("destroy");
  }

  private ratingRateHandler = (value: string) => {
    this.inputTarget.value = value;
  };
}
