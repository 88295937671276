import $ from "jquery";
import { Controller } from "stimulus";

export default class ModalController extends Controller {
  public connect() {
    this.element.addEventListener("modal:open", this.modalOpenHandler);
    this.element.addEventListener("modal:hidden", this.modalHiddenHandler);

    if (this.data.has("open")) {
      this.openModal(this.data.get("open") as string);

      this.data.delete("open");
    }
  }

  public disconnect() {
    this.element.removeEventListener("modal:open", this.modalOpenHandler);
    this.element.removeEventListener("modal:hidden", this.modalHiddenHandler);
  }

  public show(event: Event) {
    if (this.eventIsSignificant(event)) {
      const currentTarget = event.currentTarget as HTMLElement;

      if (currentTarget.dataset.modalPath) {
        event.preventDefault();

        this.openModal(currentTarget.dataset.modalPath);
      }
    }
  }

  private modalOpenHandler = (event: Event) => {
    const customEvent = event as CustomEvent;

    if (customEvent.detail) {
      this.openModal(customEvent.detail);
    }
  };

  private modalHiddenHandler = () => {
    this.data.delete("active");
  };

  private openModal(path: string) {
    if (!this.data.has("active")) {
      this.data.set("active", "true");

      // Call modal() so that Semantic-UI puts the element in the DOM.
      $(this.createModal(path)).modal();
    }
  }

  private createModal(path: string): HTMLDivElement {
    const modalElement = document.createElement("div");
    modalElement.style.minHeight = "200px";
    modalElement.classList.add("ui", "mini", "modal");
    modalElement.dataset.controller = "modal";
    modalElement.dataset.modalPath = path;

    this.createLoader(modalElement);

    return modalElement;
  }

  private createLoader(container: HTMLDivElement) {
    // Show the spinner to indicate that we're loading the contents of the
    // transaction modal.
    const dimmer = document.createElement("div");
    dimmer.classList.add("ui", "active", "inverted", "dimmer");
    container.appendChild(dimmer);

    const loader = document.createElement("div");
    loader.textContent = this.data.get("load-text") || "Loading";
    loader.classList.add("ui", "text", "loader");
    dimmer.appendChild(loader);
  }

  private eventIsSignificant(event: Event): boolean {
    if (!(event.currentTarget instanceof HTMLElement)) {
      return false;
    } else if (event instanceof MouseEvent) {
      const mouseEvent = event as MouseEvent;

      if (mouseEvent.type === "click") {
        const target = event.target as HTMLElement;
        if (target && target.isContentEditable) {
          return false;
        }

        return !(
          mouseEvent.defaultPrevented ||
          mouseEvent.which > 1 ||
          mouseEvent.altKey ||
          mouseEvent.ctrlKey ||
          mouseEvent.metaKey ||
          mouseEvent.shiftKey
        );
      }
    }
    return true;
  }
}
