import { Controller } from "stimulus";

export default class GoogleEventController extends Controller {
  public connect() {
    addEventListener("load", this.sendEvent);
  }

  public disconnect() {
    removeEventListener("load", this.sendEvent);
  }

  public sendEvent = () => {
    window.gtag("event", this.name);
  };

  private get name(): string {
    return this.data.get("name")!;
  }
}
