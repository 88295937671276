import $ from "jquery";
import { Controller } from "stimulus";

export default class extends Controller {
  public static targets = [
    "sidebar",
    "pusher",
    "toggler"
  ];

  private sidebarTarget!: HTMLElement;
  private pusherTarget!: HTMLElement;
  private togglerTarget!: HTMLElement;

  public connect() {
    $(this.sidebarTarget).
      sidebar("setting", "transition", "overlay").
      sidebar("attach events", this.togglerTarget, "toggle");
  }

  public disconnect() {
    $(this.sidebarTarget).sidebar("destroy");
    this.sidebarTarget.classList.remove("visible", "overlay", "uncover");
    this.pusherTarget.classList.remove("dimmed");
  }
}
