import { Controller } from "stimulus";

export default class SettingsFormController extends Controller {
  public static targets = [
    "form",
    "errorMessage",
    "submitButton",
    "successMessage"
  ];

  private formTarget!: HTMLFormElement;
  private successMessageTarget!: HTMLElement;
  private hasSuccessMessageTarget!: boolean;
  private errorMessageTarget!: HTMLElement;
  private submitButtonTarget!: HTMLInputElement;

  public connect() {
    this.element.addEventListener("atomic-requests:lock", this.atomicRequestLockHandler);
    this.element.addEventListener("atomic-requests:unlock", this.atomicRequestUnlockHandler);
    this.formTarget.addEventListener("ajax:error", this.ajaxErrorHandler);
  }

  public disconnect() {
    this.element.removeEventListener("atomic-requests:lock", this.atomicRequestLockHandler);
    this.element.removeEventListener("atomic-requests:unlock", this.atomicRequestUnlockHandler);
    this.formTarget.removeEventListener("ajax:error", this.ajaxErrorHandler);
  }

  private atomicRequestLockHandler = () => {
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.classList.add("loading");
  };

  private atomicRequestUnlockHandler = () => {
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.classList.remove("loading");
  };

  private ajaxErrorHandler = () => {
    if (this.scrollToTop) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
    if (this.hasSuccessMessageTarget) {
      this.successMessageTarget.classList.remove("active");
    }
    this.errorMessageTarget.classList.add("active");
  };

  private get scrollToTop(): boolean {
    return this.data.get("scrollToTop") !== "false";
  }
}
