import { Controller } from "stimulus";
import jsQR from "jsqr";
import loadImage from "blueimp-load-image";

export default class QrInputController extends Controller {
  public static readonly targets = [
    "button",
    "fileInput",
    "inputDiv"
  ];

  private readonly fileInputTarget!: HTMLInputElement;
  private readonly buttonTarget!: HTMLElement;
  private readonly inputDivTarget!: HTMLElement;

  public connect() {
    if (this.isSupported()) {
      this.fileInputTarget.
        addEventListener("change", this.fileInputChangeHandler);

      this.buttonTarget.classList.remove("unsupported");
      this.inputDivTarget.classList.add("icon");
    } else {
      this.buttonTarget.classList.add("unsupported");
      this.inputDivTarget.classList.remove("icon");
    }
  }

  public disconnect() {
    this.fileInputTarget.
      removeEventListener("change", this.fileInputChangeHandler);
  }

  private fileInputChangeHandler = (event: Event) => {
    const resume = this.buttonTarget.dispatchEvent(
      new CustomEvent(`${this.identifier}:before`, {
        bubbles: true,
        cancelable: true
      })
    );

    const inputElement = event.target as HTMLInputElement;

    if (resume) {
      this.buttonTarget.dispatchEvent(
        new CustomEvent(`${this.identifier}:start`, {
          bubbles: true
        })
      );

      // We want to scale the image down before processing it otherwise
      // it might take a some time (800x800 was arbitrarily chosen).
      loadImage(
        inputElement.files![0],
        this.imageLoadedHandler,
        {
          canvas: true,
          maxWidth: 800,
          maxHeight: 800
        }
      );
    } else {
      inputElement.value = "";
    }
  };

  private imageLoadedHandler = (canvasOrEvent: HTMLCanvasElement | Event) => {
    this.fileInputTarget.value = "";

    if (canvasOrEvent instanceof HTMLCanvasElement) {
      const address = this.parseCanvasForAddress(canvasOrEvent);

      if (address) {
        this.dispatchSuccessEvent(address);
        return;
      }
    }

    this.dispatchErrorEvent();
  };

  private dispatchSuccessEvent(address: string) {
    this.buttonTarget.dispatchEvent(
      new CustomEvent(`${this.identifier}:success`, {
        bubbles: true,
        detail: address
      })
    );
  }

  private dispatchErrorEvent() {
    this.buttonTarget.dispatchEvent(
      new CustomEvent(`${this.identifier}:error`, {
        bubbles: true
      })
    );
  }

  private parseCanvasForAddress(canvas: HTMLCanvasElement): string | undefined {
    const imageData = canvas.getContext("2d")!.
      getImageData(0, 0, canvas.width, canvas.height);
    const code = jsQR(imageData.data, imageData.width, imageData.height);

    if (code) {
      const match = code.data.match(/(?:ethereum:\s*)?(0x[0-9a-f]{40})\b/iu);

      if (match) {
        return match[1];
      }
    }
  }

  private isSupported(): boolean {
    return "capture" in document.createElement("input");
  }
}
