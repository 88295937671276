import $ from "jquery";
import { Controller } from "stimulus";

export default class extends Controller {
  public connect() {
    const options = {
      // Set container to HTML. ref: https://github.com/listia/inkwell/pull/782
      container: document.documentElement,
      context: this.element.nextElementSibling,
      // When sticky fails to initialize because there's not enough
      // content to warrent sticky behavior, it will error in the console.
      silent: true,
      // This needs to be true to work with infinite scroll.
      observeChanges: true
    } as any;

    if (this.data.has("offset")) {
      const offset = parseInt(this.data.get("offset") as string, 10);

      if (offset) {
        options.offset = offset;
      }
    }

    $(this.element).
      sticky(options).
      sticky("refresh");
  }

  public disconnect() {
    $(this.element).sticky("destroy");
  }
}
