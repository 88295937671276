import "whatwg-fetch";
import { Controller } from "stimulus";
import Cookies from "js-cookie";
import Rails from "rails-ujs";
import Fortmatic from "fortmatic";
import Web3 from "web3";

namespace Ethereum {
  export interface Transaction {
    readonly from: string;
    readonly to: string;
    readonly data: string;
  }

  export interface DispatchPayload {
    // eslint-disable-next-line camelcase
    readonly transaction_id?: number;
    readonly type: string;
    readonly details?: any;
  }

  export interface Dispatch {
    readonly path: string;
    readonly payload: DispatchPayload;
  }

  export interface Metadata {
    readonly title: string;
  }

  export interface Response {
    readonly transaction: Transaction;
    readonly dispatch: Dispatch;
    readonly metadata: Metadata;
  }

  export interface JsonRPCResponse {
    readonly id: number;
    readonly jsonrpc: string;
    readonly result: string;
    readonly error: any;
  }

  export type Callback = (error: Error, transactionHash: string) => void;
}

interface SignatureStore {
  [key: string]: string;
}

enum ProviderName {
  MetaMask = "MetaMask",
  Trust = "Trust",
  CoinbaseWallet = "Coinbase Wallet",
  Fortmatic = "Fortmatic",
  Test = "Test",
  Unknown = ""
}

export default class Web3Controller extends Controller {
  private static readonly web3TestCookie = "_web3_test_account";
  private static readonly web3FortmaticCookie = "_web3_fortmatic_account";
  private static readonly accountFetchInterval = 1000;
  private web3!: Web3;
  private timeoutId: number | undefined;
  private signatureStore: SignatureStore = {};
  private dimmerElement!: HTMLDivElement;
  private cachedProviderName?: string;
  private cachedTesting: boolean;
  private previousDocumentTitle: string;

  public connect() {
    addEventListener("ajax:success", this.ajaxSuccessHandler);
    addEventListener("ajax:beforeSend", this.ajaxBeforeSendHandler);
    addEventListener("web3:enable", this.enable);
    addEventListener("web3:testAccountUpdated", this.testAccountUpdatedHandler);

    if (this.account) {
      this.setup();
    }
  }

  public disconnect() {
    removeEventListener("ajax:success", this.ajaxSuccessHandler);
    removeEventListener("ajax:beforeSend", this.ajaxBeforeSendHandler);
    removeEventListener("web3:enable", this.enable);
    removeEventListener("web3:testAccountUpdated",
      this.testAccountUpdatedHandler);

    this.dimmer(false);

    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
  }

  public enable = () => {
    if (!this.web3) {
      this.setup();
    }
  };

  public enableFortmatic = () => {
    this.setupFortmatic();
  };

  private setup() {
    if (this.testing) {
      this.setupTestEthereum();
    } else if (Cookies.get(Web3Controller.web3FortmaticCookie) === '1') {
      this.setupFortmatic();
    } else if (window.ethereum) {
      // Modern dapp browsers (EIP-1102)
      this.setupEthereum();
    } else if (window.web3) {
      // Legacy dapp browsers
      this.setupLegacyEthereum();
    } else {
      // Non-dapp browsers
      this.updateAccount();
    }
  }

  private async setupEthereum() {
    // eslint-disable-next-line no-underscore-dangle
    const metamask = window.ethereum._metamask;
    const accounts = await window.ethereum.request({ method: 'eth_accounts' });

    if (metamask && this.account) {
      if (await metamask.isUnlocked() && accounts.length) {
        this.enableEthereum();
      } else {
        this.updateAccount();
      }
    } else {
      this.enableEthereum();
    }
  }

  private setupLegacyEthereum() {
    this.setupWeb3(new Web3(window.web3!.currentProvider));
  }

  private setupFortmatic() {
    try {
      const fm = new Fortmatic('pk_live_2B27196CFAE0FCAC');
      this.setupWeb3(new Web3(fm.getProvider()));
    } catch (error) {
      this.updateAccount();
    }
  }

  private setupTestEthereum() {
    const host = this.data.get("http")!;
    const provider = new Web3.providers.HttpProvider(host);
    this.setupWeb3(new Web3(provider));
  }

  private testAccountUpdatedHandler = () => {
    this.getWeb3Account();
  };

  private async enableEthereum() {
    try {
      await window.ethereum.enable();
      this.setupWeb3(new Web3(window.ethereum));
    } catch (error) {
      this.updateAccount();
    }
  }

  private setupWeb3(instance: Web3) {
    this.web3 = instance;

    this.getWeb3Account();
  }

  // eslint-disable-next-line max-statements
  private ajaxBeforeSendHandler = (event: Event) => {
    const eventTarget = event.target as HTMLElement;
    const message = eventTarget.dataset.web3Message as string;

    if (this.eventFromWeb3Form(event) === "sign") {
      const storedSignature = this.signatureStore[message];

      if (storedSignature) {
        const customEvent = event as CustomEvent;
        customEvent.detail[1].data =
          `${customEvent.detail[1].data}&signature=${storedSignature}`;
      } else {
        event.preventDefault();

        this.dimmer(true);
        this.sign(message, (error: Error, signature: string) => {
          this.web3SignMessageHandler(
            error,
            eventTarget,
            signature,
            message
          );
        });
      }
    }
  };

  private sign(
    message: string,
    callback: (error: Error, signature: string) => void
  ) {
    if (this.testing) {
      this.web3.eth.sign(message, this.account!, callback);
    } else {
      const request = {
        method: "personal_sign",
        params: [
          message,
          this.account
        ]
      };
      // Have to use provider's sendAsync function since we do not
      // own user's private key.
      this.provider.sendAsync(
        request,
        (error: Error, response: Ethereum.JsonRPCResponse) => {
          callback(error, response.result);
        }
      );
    }
  }

  private ajaxSuccessHandler = (event: Event) => {
    const customEvent = event as CustomEvent;
    const response = customEvent.detail[0] as Ethereum.Response;

    if (this.eventFromWeb3Form(event) === "transaction") {
      this.dimmer(true);

      if (this.providerName === ProviderName.CoinbaseWallet) {
        this.previousDocumentTitle = document.title;
        document.title = response.metadata.title;
      }

      this.web3.eth.sendTransaction(
        response.transaction,
        (error: Error, txHash: string) => {
          this.web3SendTransactionHandler(
            error,
            txHash,
            customEvent,
            response
          );
        }
      );
    }
  };

  // eslint-disable-next-line max-params
  // eslint-disable-next-line space-before-function-paren
  private web3SendTransactionHandler = async (
    error: Error,
    txHash: string,
    ajaxSuccessEvent: CustomEvent,
    response: Ethereum.Response
  ) => {
    this.dimmer(false);

    if (this.providerName === ProviderName.CoinbaseWallet) {
      document.title = this.previousDocumentTitle;
    }

    if (error) {
      this.dispatchErrorEvent(ajaxSuccessEvent.target!, error);
    } else {
      await this.reportDispatch(response.dispatch, txHash);
      this.dispatchSuccessEvent(ajaxSuccessEvent.target!, txHash);
    }
  };

  // eslint-disable-next-line max-params
  private web3SignMessageHandler = (
    error: Error,
    element: HTMLElement,
    signature: string,
    message: string
  ) => {
    this.dimmer(false);

    // MetaMask currently raises an error but fails to set the `error` variable
    // so we have to check for the presence of a signature.
    if (error || !signature) {
      this.dispatchErrorEvent(element, error);
    } else {
      this.signatureStore[message] = signature;

      Rails.fire(element, "submit");
    }
  };

  private getWeb3Account(delay: number = 0) {
    this.timeoutId = window.setTimeout(
      this.web3.eth.getAccounts,
      delay,
      this.getAccountsHandler
    );
  }

  private getAccountsHandler = (error: Error, accounts: string[]) => {
    if (error) {
      this.updateAccount();
    } else if (this.updateAccount(accounts[0]) && !this.testing) {
      this.getWeb3Account(Web3Controller.accountFetchInterval);
    }
  };

  private updateAccount(newAccount?: string): boolean {
    const account = this.normalizeAccount(newAccount);

    if (this.account === account) {
      // Account has not changed, no need to do anything.
      return true;
    }

    if (this.account) {
      this.clearSession();
    } else {
      this.createSession(account!);
    }

    return false;
  }

  private normalizeAccount(account?: string): string | null {
    let normalizedAccount = account || null;

    if (this.testing) {
      if (this.testAccount) {
        this.web3.eth.defaultAccount = this.testAccount;
        normalizedAccount = this.testAccount;
      } else if (this.web3.eth.defaultAccount) {
        this.web3.eth.defaultAccount = null;
      }
    }

    if (normalizedAccount) {
      normalizedAccount = normalizedAccount.toLowerCase();
    }

    return normalizedAccount;
  }

  private async reportDispatch(
    dispatch: Ethereum.Dispatch,
    txHash: string
  ) {
    // POST to /transact
    const options: RequestInit = {
      method: "POST",
      headers: this.headersForFetch,
      credentials: "same-origin",
      body: JSON.stringify({
        // eslint-disable-next-line camelcase
        transaction_hash: txHash,
        payload: dispatch.payload
      })
    };

    await fetch(dispatch.path, options);
  }

  private eventFromWeb3Form(event: Event): string | undefined {
    if (event.target && event.target instanceof HTMLFormElement) {
      const form = event.target as HTMLFormElement;
      return form.dataset.web3;
    }
  }

  private dimmer(visible: boolean) {
    if (visible) {
      this.enableDimmer();
    } else if (this.dimmerElement) {
      this.disableDimmer();
    }
  }

  // eslint-disable-next-line max-statements
  private enableDimmer() {
    if (!this.dimmerElement) {
      const loader = document.createElement("div");
      loader.classList.add("ui", "large", "text", "loader");
      loader.textContent = "Waiting for transaction…";

      this.dimmerElement = document.createElement("div");
      this.dimmerElement.classList.add("ui", "active", "dimmer");
      this.dimmerElement.appendChild(loader);
    }

    if (document.body.classList.contains("dimmed") &&
      document.body.classList.contains("dimmable")) {
      this.dimmerElement.dataset.dimmed = "true";
    } else {
      document.body.classList.add("dimmed", "dimmable");
    }
    document.body.appendChild(this.dimmerElement);
  }

  private disableDimmer() {
    if (this.dimmerElement.dataset.dimmed !== "true") {
      document.body.classList.remove("dimmed");
      document.body.classList.remove("dimmable");
    }
    this.dimmerElement.remove();
  }

  private dispatchErrorEvent(target: EventTarget, detail: any) {
    target.dispatchEvent(new CustomEvent("web3:error", {
      bubbles: true,
      detail: detail
    }));
  }

  private dispatchSuccessEvent(target: EventTarget, detail: any) {
    target.dispatchEvent(new CustomEvent("web3:success", {
      bubbles: true,
      detail: detail
    }));
  }

  private async createSession(account: string) {
    // eslint-disable-next-line camelcase
    if (this.providerName === ProviderName.Fortmatic) {
      Cookies.set(Web3Controller.web3FortmaticCookie, '1');
    }
    await fetch(this.sessionPath, {
      method: "POST",
      headers: this.headersForFetch,
      credentials: "same-origin",
      body: JSON.stringify({
        account: account,
        provider: this.providerName
      })
    });
    location.reload();
  }

  private async clearSession() {
    Cookies.remove(Web3Controller.web3FortmaticCookie);
    await fetch(this.sessionPath, {
      method: "DELETE",
      headers: this.headersForFetch,
      credentials: "same-origin"
    });
    location.reload();
  }

  private get headersForFetch(): HeadersInit {
    return {
      "Content-Type": "application/json",
      "X-CSRF-Token": Rails.csrfToken()
    };
  }

  private get testing(): boolean {
    if (typeof this.cachedTesting === "undefined") {
      this.cachedTesting =
        Boolean(document.head!.querySelector("[name=test][content=true]"));
    }
    return this.cachedTesting;
  }

  private get testAccount(): string | null {
    return Cookies.get(Web3Controller.web3TestCookie);
  }

  private get provider(): any {
    return this.web3.currentProvider;
  }

  private get providerName(): string {
    if (typeof this.cachedProviderName === "undefined") {
      // https://ethereum.stackexchange.com/a/43649
      if (this.provider.isMetaMask) {
        this.cachedProviderName = ProviderName.MetaMask;
      } else if (this.provider.isTrust) {
        this.cachedProviderName = ProviderName.Trust;
      } else if (Object.prototype.hasOwnProperty.call(window, "SOFA")) {
        this.cachedProviderName = ProviderName.CoinbaseWallet;
      } else if (this.provider.isFortmatic) {
        this.cachedProviderName = ProviderName.Fortmatic;
      } else if (this.testing) {
        this.cachedProviderName = ProviderName.Test;
      } else {
        this.cachedProviderName = ProviderName.Unknown;
      }
    }

    return this.cachedProviderName;
  }

  private get account(): string | null {
    return this.data.get("account");
  }

  private get sessionPath(): string {
    return this.data.get("sessionPath")!;
  }
}
