import { Controller } from "stimulus";

declare global {
  interface Window {
    dataLayer: IArguments[];
    gtag(...any);
  }
}

export default class GoogleAnalyticsController extends Controller {
  private static scriptUrl = "https://www.googletagmanager.com/gtag/js?id=";

  public initialize() {
    window.dataLayer = window.dataLayer || [];
    window.gtag = this.gtag;

    const script = document.createElement("script");
    script.async = true;
    script.src = `${GoogleAnalyticsController.scriptUrl}${this.id}`;
    document.head!.appendChild(script);
  }

  public connect() {
    this.gtag("js", new Date());

    document.addEventListener("turbolinks:load", this.tubolinksLoadHandler);
  }

  private gtag(..._args) {
    // Google Analytics requires the native `arguments` object.
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  }

  private tubolinksLoadHandler = (event) => {
    // eslint-disable-next-line camelcase
    this.gtag("config", this.id, { page_location: event.data.url });
  };

  private get id(): string {
    return this.data.get("id")!;
  }
}
